import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { AuthContext } from './contexts/Auth';
import AppBar from './AppBar';

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { isSignedIn, signOut, user } = useContext(AuthContext);

  return (
    <div>
      <AppBar
        siteTitle={data.site.siteMetadata.title}
        isSignedIn={isSignedIn}
        signOut={signOut}
        user={user}
      />
      <div
        style={{
          margin: '0 auto',
          maxWidth: '700px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
