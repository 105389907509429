import React from 'react';
import PropTypes from 'prop-types';
import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { cosmicpuma } from '@nonsequitur/component-library';

const { Button } = cosmicpuma;

const styles = (props) => {
  const {
    theme: {
      spacingS,
      spacingL,
      colorBackground,
      colorBackgroundSecondary,
    },
    isOpen,
  } = props;

  return css`
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${colorBackgroundSecondary};
    position: fixed;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled) {
        background: ${colorBackground};
        border-right: 1px solid ${colorBackgroundSecondary};
      }
    }

    .rightnav-actions {
      margin-left: ${spacingL};
      margin-right: ${spacingL};
      padding-top: ${spacingS};
      padding-bottom: ${spacingS};
    }
  `;
};

const RightNav = (props) => {
  const {
    actions,
    classes,
    className,
  } = props;

  return (
    <ul
      className={className}
    >
      {actions.map((props) => (
        <li
          key={props.children} // eslint-disable-line react/prop-types
        >
          <Button
            {...props}
            className={classes.actions}
          />
        </li>
      ))}
    </ul>
  );
};

RightNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    actions: PropTypes.string,
  }),
};

RightNav.defaultProps = {
  className: undefined,
  classes: {
    actions: 'rightnav-actions',
  },
};

const StyledRightNav = styled(RightNav)`${styles}`;

StyledRightNav.defaultProps = {
  ...RightNav.defaultProps,
};

export default withTheme(StyledRightNav);
