import React from 'react';
import PropTypes from 'prop-types';
import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled';

const styles = (props) => {
  const {
    theme: {
      colorPrimary,
      spacingL,
      colorText,
    },
    isOpen,
  } = props;

  return css`
    width: 2rem;
    height: 2rem;
    margin-left: ${spacingL};
    z-index: 20;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;

    div {
      width: 2rem;
      height: 0.25rem;
      background-color: ${isOpen ? colorPrimary : colorText};
      border-radius: 10px;
      transform-origin: 1px;
      transition: all 0.3s linear;

      &:nth-of-type(1) {
        transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'};
      }

      &:nth-of-type(2) {
        transform: ${isOpen ? 'translateX(100%)' : 'translateX(0)'};
        opacity: ${isOpen ? 0 : 1};
      }

      &:nth-of-type(3) {
        transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
  `;
};

const Burger = (props) => {
  const {
    handleClick,
    className,
  } = props;

  return (
    <>
      <div
        onClick={handleClick}
        className={className}
        role="button"
        tabIndex="0"
      >
        <div />
        <div />
        <div />
      </div>
    </>
  );
};

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Burger.defaultProps = {
  className: undefined,
};

const StyledBurger = styled(Burger)`${styles}`;

StyledBurger.defaultProps = {
  ...Burger.defaultProps,
};

export default withTheme(StyledBurger);
